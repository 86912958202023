import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import {
  DateTimeCell,
} from '../components/cells';
import ProgressBarCell from '../components/cells/ProgressBarCell';
import FingoGridNode from '../components/dataGrids/FingoGridNode';

const useCreditLinePreColumns = (
  customCreditLineColumns = [],
) => {
  const creditLineDefaultPreColumns = [
    {
      field: 'lastUpdate',
      type: 'date',
      headerName: 'Fecha última actualización',
      sortable: false,
      filterable: false,
      maxWidth: 150,
      renderCell: ({ row }) => (
        <DateTimeCell fullDate={moment(row?.lastUpdate)} />
      ),
    },
    {
      field: 'creditUsed',
      headerName: 'Línea usada',
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: ({ row }) => (
        <ProgressBarCell
          value={row?.creditUsed || 0}
          total={row?.currentLimit?.newLimit?.amount || 0}
          isMoney
          currency={row?.currency.isoCode}
          startTooltipTitle="Línea usada"
          endTooltipTitle="Línea total"
        />
      ),
    },
    {
      field: 'currency',
      headerName: 'Divisa',
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Typography>{row?.currency.isoCode}</Typography>
      ),
    },
  ];
  const creditLinePreColumns = creditLineDefaultPreColumns.map(
    (defaultColumn) => customCreditLineColumns.find(
      (customColumn) => customColumn.field === defaultColumn.field,
    ) || defaultColumn,
  );
  const newPreColumns = customCreditLineColumns.filter(
    (customColumn) => !creditLineDefaultPreColumns
      .map((defaultColumn) => defaultColumn.field)
      .includes(customColumn.field),
  );
  const columns = [...creditLinePreColumns, ...newPreColumns];
  return columns.map(({ renderCell, ...restParams }) => {
    if (renderCell) {
      return {
        ...restParams,
        renderCell: (params) => (
          <FingoGridNode params={params} renderCell={renderCell} />
        ),
      };
    }
    return restParams;
  });
};

export default useCreditLinePreColumns;
