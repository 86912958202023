import React from 'react';
import { Typography } from '@mui/material';
import { formatMoney } from '../helpers';

const BusinessProfileVehicleColumns = [
  {
    field: 'brand',
    headerName: 'Marca',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>{row.brand}</Typography>
    ),
  },
  {
    field: 'model',
    headerName: 'Modelo',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{row.model}</Typography>
    ),
  },
  {
    field: 'year',
    headerName: 'Año',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <Typography>{row.year}</Typography>
    ),
  },
  {
    field: 'licensePlace',
    headerName: 'Patente',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <Typography>{row.licensePlace}</Typography>
    ),
  },
  {
    field: 'carType',
    headerName: 'Tipo Vehiculo',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <Typography>{row.carType}</Typography>
    ),
  },
  {
    field: 'appraisal',
    headerName: 'Apreciación',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => <Typography>${formatMoney(row.appraisal)}</Typography>,
  },
];

export default BusinessProfileVehicleColumns;
